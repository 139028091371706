define("ember-composable-helpers/helpers/join", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute(_ref) {
      let [separator, array] = _ref;
      if (Ember.isArray(separator)) {
        array = separator;
        separator = ',';
      }
      Ember.set(this, 'array', array);
      return array.join(separator);
    },
    arrayContentDidChange: Ember.observer('array.[]', function () {
      this.recompute();
    })
  });
});