define("ember-math-helpers/helpers/atanh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.atanh = atanh;
  _exports.default = void 0;
  /**
   * Executes `Math.atanh` on the number passed to the helper.
   *
   * ```hbs
   * {{atanh a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.atanh`
   * @return {number} The atanh of the passed number
   */
  function atanh(_ref) {
    let [number] = _ref;
    return Math.atanh(number);
  }
  var _default = _exports.default = Ember.Helper.helper(atanh);
});