define("ember-composable-helpers/helpers/find-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute(_ref) {
      let [byPath, value, array] = _ref;
      Ember.set(this, 'array', array);
      Ember.set(this, 'byPath', byPath);
      Ember.set(this, 'value', value);
      return Ember.get(this, 'content');
    },
    byPathDidChange: Ember.observer('byPath', function () {
      let byPath = Ember.get(this, 'byPath');
      if (Ember.isEmpty(byPath)) {
        Ember.defineProperty(this, 'content', []);
        return;
      }
      Ember.defineProperty(this, 'content', Ember.computed("array.@each.".concat(byPath), 'value', function () {
        let array = Ember.get(this, 'array');
        let value = Ember.get(this, 'value');
        return Ember.A(array).findBy(byPath, value);
      }));
    }),
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });
});