define("ember-composable-helpers/helpers/invoke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.invoke = invoke;
  const {
    all
  } = Ember.RSVP;
  function invoke(_ref) {
    let [methodName, ...args] = _ref;
    let obj = args.pop();
    if (Ember.isArray(obj)) {
      return function () {
        let promises = obj.map(item => Ember.tryInvoke(item, methodName, args));
        return all(promises);
      };
    }
    return function () {
      return Ember.tryInvoke(obj, methodName, args);
    };
  }
  var _default = _exports.default = Ember.Helper.helper(invoke);
});