define("ember-composable-helpers/helpers/has-next", ["exports", "ember-composable-helpers/helpers/next", "ember-composable-helpers/-private/create-needle-haystack-helper", "ember-composable-helpers/utils/is-equal"], function (_exports, _next, _createNeedleHaystackHelper, _isEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasNext = hasNext;
  function hasNext(currentValue, array) {
    let useDeepEqual = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    let nextValue = (0, _next.next)(currentValue, array, useDeepEqual);
    let isNotSameValue = !(0, _isEqual.default)(nextValue, currentValue, useDeepEqual);
    return isNotSameValue && Ember.isPresent(nextValue);
  }
  var _default = _exports.default = (0, _createNeedleHaystackHelper.default)(hasNext);
});