define("ember-composable-helpers/helpers/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.array = array;
  _exports.default = void 0;
  function array() {
    let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    // slice params to avoid mutating the provided params
    return Ember.A(params.slice());
  }
  var _default = _exports.default = Ember.Helper.helper(array);
});